import React from "react";
import { View } from "react-native";
import { useTheme } from "react-native-elements";
import LogoSvg from "../components/LogoSvg";
import { AppTheme as theme, scale } from "../contexts/Theme";

interface HeaderProps {
  setHeaderHeight: (headerHeight: number) => void;
}

export const Header = ({ setHeaderHeight }: HeaderProps) => {
  const { theme } = useTheme();

  return (
    <View
      // @ts-ignore
      style={{
        justifyContent: "center",
        paddingVertical: 20,
        borderBottomWidth: 0.5,
        borderColor: theme.colors?.grey4,
        // this works but is undefined behavior based on the type check
        position: "fixed",
        zIndex: 1,
        backgroundColor: "rgba(256,256,256,0.8)",
        width: "100%",
        backdropFilter: "blur(2px)",
      }}
      onLayout={({
        nativeEvent: {
          layout: { height },
        },
      }) => {
        setHeaderHeight(height);
      }}
    >
      <LogoSvg
        width="30%"
        style={{
          height: "auto",
          maxWidth: scale(250),
          minWidth: scale(200),
          // maxHeight: scale(70),
          paddingLeft: "5%",
        }}
        preserveAspectRatio="xMinYMin"
      />
      {/* <img
        src={require("./assets/NFT500-Logo.svg")}
        style={{
          maxWidth: scale(300),
          minWidth: "50%",
          maxHeight: scale(70),
          paddingLeft: "5%",
        }}
      /> */}
    </View>
  );
};
