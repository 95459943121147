import React from "react";
import { useWindowDimensions, View } from "react-native";
import { ThemeContext } from "react-native-elements";
import {
  DataProvider,
  LayoutProvider,
  RecyclerListView,
  RecyclerListViewProps,
} from "recyclerlistview/web";
import { getHeight } from "../common/math";
import { NFTListContext } from "../contexts/NFTList";
import { GalleryItem, itemMargin } from "./GalleryItem";
import { bottomBannerHeight } from "./RenderItem";

export interface GalleryProps {}

export const Gallery = ({}: GalleryProps) => {
  const { nftList } = React.useContext(NFTListContext);

  const nftData = React.useMemo(
    () =>
      nftList.map((nft_, j) => {
        let nft = JSON.parse(JSON.stringify(nft_));
        nft.idx = j;
        return nft;
      }),
    [nftList.length]
  );

  const windowWidth = useWindowDimensions().width;
  const [width, setWidth] = React.useState(windowWidth);
  React.useEffect(() => {
    setWidth(windowWidth > 410 ? 410 : windowWidth < 200 ? 200 : windowWidth);
  }, [windowWidth]);

  interface Range {
    start: number;
    end: number;
  }

  const { theme } = React.useContext(ThemeContext);

  const _dataProvider = new DataProvider((r1, r2) => r1 !== r2);
  const dataProvider = React.useMemo(
    () => _dataProvider.cloneWithRows(nftData),
    [nftData]
  );
  const renderOffset = 2;
  const vidLoadOffset = 0; // only load those on screen

  const layoutProvider = React.useMemo(
    () =>
      new LayoutProvider(
        (index) => 0,
        (type, dim, index) => {
          dim.width = windowWidth;
          dim.height = getHeight(width, nftData[index]) + bottomBannerHeight;
        }
      ),
    [width, nftData]
  );

  return (
    <View
      style={{
        flex: 1,
        height: "100%",
        width: windowWidth,
        backgroundColor: theme.colors!.bg,
        overflow: "hidden",
      }}
    >
      {nftData.length > 0 && width > 0 && (
        <RecyclerListView
          dataProvider={dataProvider}
          layoutProvider={layoutProvider}
          useWindowScroll={true}
          rowRenderer={(_, data, index) => (
            <GalleryItem
              width={width}
              item={data}
              index={index}
              vidLoadOffset={vidLoadOffset}
            />
          )}
          // canChangeSize={true}
          forceNonDeterministicRendering={true}
          style={{
            flex: 1,
            paddingBottom: itemMargin,
          }}
          renderAheadOffset={
            500 /* rough average piece height */ * renderOffset
          }
        />
      )}
    </View>
  );
};
