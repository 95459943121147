import React from "react";
import { View, ViewStyle, ActivityIndicator } from "react-native";
import { useTheme } from "react-native-elements";
import Lottie from "react-lottie";

interface LoaderProps {
  containerStyle?: ViewStyle;
  style?: ViewStyle;
  brand?: boolean;
}

export const Loader = ({
  containerStyle,
  style,
  brand = false,
}: LoaderProps) => {
  const { theme } = useTheme();

  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        ...containerStyle,
      }}
    >
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          width: "100%",
          height: "100%",
          position: "absolute",
          backgroundColor: theme.colors?.grey0,
          ...style,
        }}
      >
        {/* <Text>Loading...</Text> */}
        {/* <LoadingImage
            width={width}
           newStyles={commonStyles.media}
          /> */}
        {!brand ? (
          <ActivityIndicator size="large" />
        ) : (
          <View
            style={{
              borderRadius: theme.border?.radius,
              overflow: "hidden",
              backgroundColor: "rgba(256,256,256,0.7)",
              padding: "2%",
              width: "25%",
            }}
          >
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: require("../assets/logo_anim.json"),
              }}
            />
          </View>
        )}
      </View>
    </View>
  );
};
