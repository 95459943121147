import React from "react";
import { useWindowDimensions, View } from "react-native";
import { useTheme } from "react-native-elements";
import { NFTListContext, NFTResp } from "../contexts/NFTList";
import { RenderItem } from "./RenderItem";
import { RightFooterRenderItem } from "./RightFooterRenderItem";

export const itemMargin = 40;

export interface Range {
  start: number;
  end: number;
}

export interface GalleryItemProps {
  width: number;
  item: NFTResp;
  index: number;
  // how many vids ahead and behidn to load
  vidLoadOffset?: number;
}

export const GalleryItem = ({
  width,
  item,
  index,
  vidLoadOffset,
}: GalleryItemProps) => {
  const { mutedIdx, setMutedIdx } = React.useContext(NFTListContext);

  const { theme } = useTheme();

  const setMuted = (muted: boolean) => {
    if (!muted) {
      setMutedIdx(index);
    } else {
      setMutedIdx(-1);
    }
  };
  const muted = mutedIdx != index;

  const windowWidth = useWindowDimensions().width;

  React.useEffect(() => {
    if (!muted) setMuted(!muted);
  }, [item.video]);

  vidLoadOffset = vidLoadOffset !== undefined ? vidLoadOffset : 0;

  return (
    <View
      style={{
        width: windowWidth,
        height: "100%",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginTop: itemMargin,
      }}
    >
      <RenderItem
        width={width - width * theme.margins!.main! * 2}
        media={item}
        muted={muted}
        rightFooterComponent={
          <RightFooterRenderItem
            media={item}
            muted={muted}
            setMuted={setMuted}
          />
        }
      />
    </View>
  );
};
