import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="prefix__NFT500-Logo_png_NFT500-Logo_svg"
      data-name="NFT500-Logo.png, NFT500-Logo.svg"
      xmlns="http://www.w3.org/2000/svg"
      width={1378.72}
      height={265.031}
      viewBox="0 0 1378.72 265.031"
      {...props}
    >
      <defs>
        <style>{".prefix__cls-1{fill:#222;fill-rule:evenodd}"}</style>
      </defs>
      <path
        id="prefix__NFT500"
        className="prefix__cls-1"
        d="M623.965 491.994v132.734L515.948 491.994h-29.942v196.021h36.1V555.281l108.015 132.734h29.943V491.994h-36.1zM827.7 522.517v-30.523h-143v196.021h36.379v-75.048h94.585v-30.8h-94.587v-59.65H827.7zm82 165.5h36.379V522.8h64.917V492H844.772v30.8h64.922v165.215zm220.376-12.251q16.35-12.252 19.92-32.944l3.96-22.839q3.705-21.5-7.15-32.4-10.875-10.887-32.84-10.891h-82.12l11.43-66.711h112.05c4.2 0 8.16-2.2 8.94-6.8l.8-4.39c.79-4.6-2.37-6.8-6.58-6.8H1039.6q-5.145 0-7.85 2.314t-3.6 7.487l-14.32 82.821q-.885 5.175 1.02 7.487t7.06 2.314h95.22c12.7 0 22.52 10.539 20.52 22.262l-5.27 30.633c-2.07 12.175-15.04 23.262-28.3 23.262h-97.93q-5.16 0-7.861 2.314t-3.6 7.487l.315-2.155q-.893 5.175 1.015 7.487t7.056 2.314h86.24q24.41-.003 40.755-12.254zm214.51-132a66.869 66.869 0 00.38-22.712 30.4 30.4 0 00-9.75-17.544q-7.98-7.344-23.45-11.424c-10.32-2.72-24.15-2.08-40.82-2.08s-30.3-.64-41.55 2.08S1209 498.61 1202 503.506a50.1 50.1 0 00-15.94 17.544 75.238 75.238 0 00-7.59 22.712l-16 92.752a64.431 64.431 0 00-.25 22.712 30.474 30.474 0 009.92 17.408q7.995 7.209 23.47 11.288c10.31 2.72 24.15 2.08 40.82 2.08s30.3.64 41.55-2.08 20.37-6.482 27.37-11.288a47.8 47.8 0 0015.77-17.408 77.636 77.636 0 007.46-22.712zm-146.1-3.044c4.24-24.718 25.94-30.25 68.91-30.25s62.77 5.532 58.53 30.25l-17.05 98.722c-4.14 23.913-26.16 31.647-69.16 31.647-42.93 0-62.42-7.734-58.29-31.647zm327.1 3.044a66.869 66.869 0 00.38-22.712 30.4 30.4 0 00-9.75-17.544q-7.98-7.344-23.45-11.424c-10.32-2.72-24.15-2.08-40.82-2.08s-30.3-.64-41.55 2.08S1390 498.61 1383 503.506a50.1 50.1 0 00-15.94 17.544 75.238 75.238 0 00-7.59 22.712l-16 92.752a64.431 64.431 0 00-.25 22.712 30.474 30.474 0 009.92 17.408q7.995 7.209 23.47 11.288c10.31 2.72 24.15 2.08 40.82 2.08s30.3.64 41.55-2.08 20.37-6.482 27.37-11.288a47.8 47.8 0 0015.77-17.408 77.636 77.636 0 007.46-22.712zm-146.1-3.044c4.24-24.718 25.94-30.25 68.91-30.25s62.77 5.532 58.53 30.25l-17.05 98.722c-4.14 23.913-26.16 31.647-69.16 31.647-42.93 0-62.42-7.734-58.29-31.647z"
        transform="translate(-148 -458)"
      />
      <g id="prefix___1600x1600_NFT500_jpg" data-name="1600x1600 NFT500.jpg">
        <path
          id="prefix__NFT500_png_NFT500-dark_svg"
          data-name="NFT500.png, NFT500-dark.svg"
          className="prefix__cls-1"
          d="M399.708 512.105L373.465 668.9C371.607 680 361.1 689 350 689h-65.957l-24.054 29.326c-5.147 6.275-11.787 6.275-14.83 0L230.936 689h-65.9c-11.1 0-18.6-9-16.741-20.1l26.243-156.79C176.393 501 186.9 492 198 492h65.953l24.054-29.326c5.147-6.275 11.787-6.275 14.83 0L317.064 492h65.9c11.107 0 18.602 9 16.744 20.105zM251.322 691.286c2.379 4.948 7.579 4.948 11.614 0l72.524-88.933-68.86 32.314-58.3-32.865zM199.357 508a9.9 9.9 0 00-9.384 8.048l-24.889 148.9a6.63 6.63 0 006.694 8.052h51.4l-34.5-71.136c-3.044-6.275-1.338-16.449 3.809-22.724L250.835 508h-51.478zm97.3-18.286c-2.379-4.948-7.579-4.948-11.614 0L210.4 581.258l59.566 33.333 70.745-33.279zM376.222 508h-51.4l34.5 71.136c3.044 6.275 1.338 16.449-3.809 22.724L297.165 673h51.478a9.9 9.9 0 009.384-8.049l24.888-148.9a6.63 6.63 0 00-6.693-8.051z"
          transform="translate(-148 -458)"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
