import { TextStyle } from "react-native";
import { Theme } from "react-native-elements";
import { FontSizeMapping, FontSizeType } from "../react-native-elements";

const textToMobileScaleFactor = 0.7;

export function scale(n: number) {
  return n * textToMobileScaleFactor;
}

class Text {
  static fontSizes(modScale = 1.0): FontSizeMapping {
    const sizes = {
      h1: AppTheme.fonts!.h1!,
      h2: AppTheme.fonts!.h2!,
      h3: AppTheme.fonts!.h3!,
      h4: AppTheme.fonts!.h4!,
    };
    const out = {} as FontSizeMapping;
    Object.entries(sizes).forEach(([k, v]) => {
      // @ts-ignore
      out[k] = Math.floor(v! * modScale);
    });
    return out;
  }
}
class SFText extends Text {
  static override fontSizes() {
    return super.fontSizes(0.85);
  }
  static fontFamily() {
    return "SF UI Text";
  }
  static get(sizeType: FontSizeType): TextStyle {
    return {
      fontSize: this.fontSizes()[sizeType],
      fontFamily: this.fontFamily(),
    };
  }
}

export const AppTheme: Theme = {
  // TODO: create getter for alpha customization
  colors: {
    bg: "white",
    primary: "black",
    iconBg: "rgb(229,229,229)",
  },
  fonts: {
    title: "Athelas",
    description: SFText.fontFamily(),
    // ref: https://reactnativeelements.com/docs/text
    h1: scale(40),
    h2: scale(34),
    h3: scale(28),
    h4: scale(22),
  },
  margins: {
    main: 0.05,
  },
  border: {
    radius: 20,
  },

  get common() {
    const inCommon: TextStyle = {
      color: this.colors!.primary!,
      fontFamily: this.fonts!.description!,
    };
    const texts = {
      text: (sizeType: FontSizeType) => ({
        ...inCommon,
        ...SFText.get(sizeType),
      }),
      title: {
        ...inCommon,
        fontFamily: this.fonts!.title!,
      } as TextStyle,
    };
    // var out = {} as {
    //   [fontKind in keyof typeof texts]: typeof texts[keyof typeof texts] &
    //     typeof inCommon;
    // };
    // Object.entries(texts).forEach(([k, v]) => {
    //   out[k as keyof typeof out] = { ...inCommon, ...v };
    // });
    return texts;
  },
};
