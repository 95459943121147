import React from "react";
import {
  // Text,
  View,
  ViewStyle,
} from "react-native";
import { useTheme } from "react-native-elements";
import { getHeight } from "../common/math";
import { NFTResp } from "../contexts/NFTList";
import { Loader } from "./Loader";

export enum MediaType {
  Image,
  Video,
  Gif,
}

// passed on when rendering media from Gallery
export interface RenderMediaProps {
  // optionals default to take up full dimension
  width: number;
  media: NFTResp;
  muted?: boolean;
  onLoad?: () => void;
  style?: ViewStyle;
}

// superset of RenderMediaProps actually passed onto each component. generic media component
interface RenderMediaComponentProps {
  width: number;
  media: NFTResp;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  playing: boolean;
  setPlaying: (loading: boolean) => void;
  muted?: boolean;
}

export const createRenderVideoState = () => {
  return {
    repeat: true,
    allowsExternalPlayback: false,
    playInBackground: false,
    playWhenInactive: true,
    resizeMode: "contain",
    pictureInPicture: false,
    ignoreSilentSwitch: "obey",
  };
};

export const RenderVideo = ({
  media,
  loading,
  playing,
  setPlaying,
  setLoading,
  muted,
}: RenderMediaComponentProps) => {
  const videoRef = React.useRef(null);

  const uri = media.video;

  // React.useEffect(() => {
  //   if (videoRef.current != null)
  //     // @ts-ignore
  //     videoRef.current!.pause().catch(() => {});
  //   // @ts-ignore
  //   else if (videoRef.current != null) videoRef.current!.play().catch(() => {});
  // }, [videoRef.current]);

  React.useEffect(() => {
    setLoading(true);
    setPlaying(false);
  }, [uri]);

  return (
    <View style={{ flex: 1 }}>
      <video
        key={uri}
        ref={videoRef}
        poster={!playing ? media.thumbnail : undefined}
        autoPlay
        preload="meta"
        playsInline
        muted={muted}
        loop
        style={{
          minHeight: "100%",
          minWidth: "100%",
          height: "auto !important",
          width: "auto !important",
        }}
        onStalled={() => {
          setLoading(true);
        }}
        onSuspend={() => {
          setLoading(false); // removes loader
          // setPlayicng(true); // removes poster
        }}
        onLoad={() => {
          setLoading(false); // removes loader
        }}
        disablePictureInPicture
        x-webkit-airplay="deny"
        onPlaying={() => {
          setPlaying(true);
        }}
      >
        <source src={uri} />
      </video>

      {loading && (
        <img
          src={media.thumbnail}
          style={{
            minHeight: "100%",
            minWidth: "100%",
            maxWidth: "100%",
            maxHeight: "100%",
            height: "auto !important",
            width: "auto !important",
            position: "absolute",
          }}
        />
      )}
    </View>
  );
};

export const RenderMedia = ({
  width,
  media,
  muted,
  onLoad,
  style,
}: RenderMediaProps) => {
  const [loading, setLoading] = React.useState(true);
  const [playing, setPlaying] = React.useState(false);

  React.useEffect(() => {
    if (!loading && onLoad !== undefined) {
      onLoad();
    }
  }, [loading]);

  const { theme } = useTheme();

  const RenderComponent = RenderVideo;
  // const RenderComponent = media.type == MediaType.Image ? RenderImage : RenderVideo;

  return (
    <>
      <View
        style={{
          width: "100%",
          height: getHeight(width, media),
          // height: width * (media.height / media.width), // * when switching from dynamic heights
        }}
      >
        <View
          style={{
            width: "100%",
            height: "100%",
            // to prevent loading image from going out of bounds
            overflow: "hidden",
            ...style,
          }}
        >
          {!loading && (
            <Loader
              containerStyle={{
                width: "100%",
                height: "100%",
              }}
              style={{
                backgroundColor: "rgba(0,0,0,0)",
                width: "99%",
              }}
              brand={true}
            />
          )}
          <RenderComponent
            width={width}
            setLoading={setLoading}
            muted={muted !== undefined ? muted : true}
            media={media}
            loading={loading}
            playing={playing}
            setPlaying={setPlaying}
          />
          {loading && (
            <Loader
              containerStyle={{
                width: "100%",
                height: "100%",
              }}
              style={{
                backgroundColor: "rgba(0,0,0,0)",
                width: "99%",
              }}
              brand={true}
            />
          )}
        </View>
      </View>
      <View
        style={{
          borderTopStartRadius: theme.border?.radius,
          borderTopEndRadius: theme.border?.radius,
          position: "absolute",
          borderWidth: 2,
          width,
          height: getHeight(width, media),
          borderColor: theme.colors!.greyOutline,
          alignSelf: "center",
        }}
        pointerEvents={playing ? undefined : "none"}
      />
    </>
  );
};
