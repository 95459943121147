import React from "react";
import { View, Text } from "react-native";
import { IconPressable } from "../components/IconPressable";
import { ThemeContext } from "react-native-elements";
import { NFTResp } from "../contexts/NFTList";

interface RightFooterRenderItemProps {
  media: NFTResp;
  muted: boolean;
  setMuted: (muted: boolean) => void;
}

export const RightFooterRenderItem = ({
  media,
  muted,
  setMuted,
}: RightFooterRenderItemProps) => {
  const { theme } = React.useContext(ThemeContext);

  return (
    <View
      style={{
        flex: 1,
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "space-between",
      }}
    >
      <View
        style={{
          flex: 1,
          justifyContent: "space-around",
        }}
      >
        {media.has_sound && (
          <IconPressable
            onPress={() => {
              setMuted(!muted);
            }}
            iconProps={{
              type: "font-awesome-5",
              name: muted ? "volume-mute" : "volume-up",
              size: theme.fonts!.h3,
            }}
          />
        )}
      </View>
    </View>
  );
};
