import React from "react";
import { TouchableWithoutFeedback, View } from "react-native";
import { Text, ThemeContext } from "react-native-elements";
import { RenderMedia, RenderMediaProps } from "./RenderMedia";
import { getHeight } from "../common/math";

export const bottomBannerHeight = 80;

interface RenderItemProps {
  rightFooterComponent?: React.ReactNode;
  disableRouting?: boolean;
}

export const RenderItem = ({
  width,
  media,
  muted,
  disableRouting,
  rightFooterComponent,
}: RenderMediaProps & RenderItemProps) => {
  const { theme } = React.useContext(ThemeContext);
  const [opacity, setOpacity] = React.useState(1);

  return (
    <View
      style={{
        width,
        shadowColor: theme.colors?.primary,
        shadowOffset: { width: 0, height: 3 },
        shadowOpacity: 0.2,
        shadowRadius: 5,
        backgroundColor: theme.colors?.bg,
        borderRadius: theme.border?.radius,
      }}
    >
      <View
        style={{
          width,
          borderRadius: theme.border?.radius,
          overflow: "hidden",
        }}
      >
        <TouchableWithoutFeedback
          disabled={
            disableRouting !== undefined && disableRouting
              ? disableRouting
              : false
          }
        >
          <View>
            <RenderMedia
              width={width}
              media={media}
              muted={muted}
              style={{
                borderTopRightRadius: theme.border?.radius! * 1.15,
                borderTopLeftRadius: theme.border?.radius! * 1.15,
                height: "99.9%",
              }}
            />

            <View
              style={{
                borderWidth: 2,
                borderTopWidth: 0,
                borderBottomStartRadius: theme.border?.radius,
                borderBottomEndRadius: theme.border?.radius,
                borderColor: theme.colors!.greyOutline,
                backgroundColor: opacity == 1.0 ? theme.colors!.bg : undefined,
                padding: 15,
                opacity: opacity,
                // height: bottomBannerHeight,
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontFamily: theme.fonts!.title,
                    color: theme.colors!.primary,
                    fontSize: theme.fonts!.h2,
                    fontWeight: "bold",
                    textAlignVertical: "center",
                  }}
                >
                  {media.name}
                </Text>
                <Text
                  style={{
                    fontFamily: theme.fonts!.title,
                    color: theme.colors!.primary,
                    fontSize: theme.fonts!.h3,
                    textAlignVertical: "center",
                  }}
                >
                  {media.artist}
                </Text>
              </View>
              <View style={{ flex: 0.1 }}>{rightFooterComponent}</View>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </View>
  );
};
