import React from "react";
import { ViewStyle } from "react-native";
import {
  TouchableHighlight,
  TouchableHighlightProps,
  View,
} from "react-native";

export { TouchableHighlightProps };
export interface HighLightPressableProps {
  children: React.ReactNode;
  onPress?: TouchableHighlightProps["onPress"];
  onLongPress?: TouchableHighlightProps["onLongPress"];
  touchableHighlightProps?: TouchableHighlightProps;
  containerStyle?: ViewStyle;
}

export const HighLightPressable = ({
  children,
  onPress,
  onLongPress,
  containerStyle,
  ...touchableHighlightProps
}: HighLightPressableProps) => {
  return (
    <TouchableHighlight
      activeOpacity={0.5}
      underlayColor="rgba(0,0,0,0)"
      onPress={onPress}
      onLongPress={onLongPress}
      {...touchableHighlightProps}
    >
      <View style={containerStyle}>{children}</View>
    </TouchableHighlight>
  );
};
