import axios from "axios";
import React from "react";
import config from "../appconfig.json";

export interface NFTResp {
  name: string;
  has_sound: boolean;
  path: string;
  original_duration: number;
  width: number;
  height: number;
  blockchain_url: string;
  artist: string;
  video: string;
  thumbnail: string;
}

export type NFTList = NFTResp[];

interface NFTListContextState {
  nftList: NFTResp[];
  loading: boolean;
  reload: () => void;
  error?: any;
  mutedIdx: number;
  setMutedIdx: (mutedIdx: number) => void;
}

// @ts-ignore
export const NFTListContext = React.createContext<NFTListContextState>();

export const NFTStore = ({ children }: { children: React.ReactNode }) => {
  const [nftList, setNftList] = React.useState<NFTResp[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState();
  const [mutedIdx, setMutedIdx] = React.useState(-1);

  const reload = React.useCallback(() => {
    setLoading(true);
    setError(undefined);
    axios
      .get(`${config.domain}/${config.devEnv}/nfts`)
      .then((resp) => {
        console.log("reload");
        if (resp.data.err !== undefined) {
          setError(resp.data.err);
        } else {
          setNftList(resp.data);
          setLoading(false);
        }
      })
      .catch((e) => setError(e));
  }, []);

  React.useEffect(() => reload(), []);

  const initState = {
    nftList,
    loading,
    reload,
    error,
    mutedIdx,
    setMutedIdx,
  };

  return (
    <NFTListContext.Provider value={initState}>
      {children}
    </NFTListContext.Provider>
  );
};
