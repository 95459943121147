export interface Dims {
  width: number;
  height: number;
}

export function mapRange(
  value: number,
  low1: number,
  high1: number,
  low2: number,
  high2: number
): number {
  "worklet";
  return low2 + ((high2 - low2) * (value - low1)) / (high1 - low1);
}

export function clamp(val: number, low: number, high: number) {
  return Math.min(Math.max(val, low), high);
}

export function cycleNext(currIdx: number, len: number) {
  return (currIdx + 1) % len;
}

export function cyclePrev(currIdx: number, len: number) {
  return (currIdx - 1 + len) % len;
}

export interface Dims {
  width: number;
  height: number;
}

export function fillContainer(object: Dims, container: Dims) {
  let width = 0,
    height = 0;

  const objAspect = object.width / object.height;
  const containerAspect = container.width / container.height;
  if (object.width > 0 && objAspect < containerAspect) {
    width = container.width;
    height = container.width * (object.height / object.width);
  } else if (object.width > 0) {
    width = container.height * (object.width / object.height);
    height = container.height;
  }

  return { width, height };
}

export function fitContainer(object: Dims, container: Dims) {
  let width = 0,
    height = 0;

  const objAspect = object.width / object.height;
  const containerAspect = container.width / container.height;
  if (object.width > 0 && objAspect < containerAspect) {
    width = container.height * (object.width / object.height);
    height = container.height;
  } else if (object.width > 0) {
    width = container.width;
    height = container.width * (object.height / object.width);
  }

  return { width, height };
}

export const getHeight = (containerWidth: number, { width, height }: Dims) => {
  return containerWidth * (height / width);
};

export function degToRadians(deg: number) {
  return deg * (Math.PI / 180);
}

export function distanceFromZero(x: number, y: number) {
  "worklet";
  return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
}

export function cumSum(a: Array<number>) {
  let result = [a[0]];

  for (let i = 1; i < a.length; i++) {
    result[i] = result[i - 1] + a[i];
  }

  return result;
}
