/* Randomize array in-place using Durstenfeld shuffle algorithm */
export function shuffle(array: any[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export function floatToPercent(float: number) {
  return `${float * 100}%`;
}
