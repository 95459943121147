import React from "react";
import { Icon, IconProps, ThemeContext } from "react-native-elements";
import {
  HighLightPressable,
  TouchableHighlightProps,
} from "./HighLightPressable";

interface IconPressableProps {
  onPress: TouchableHighlightProps["onPress"];
  iconProps: IconProps;
  touchableHighlightProps?: TouchableHighlightProps;
}

export const IconPressable = ({
  onPress,
  iconProps,
  touchableHighlightProps,
}: IconPressableProps) => {
  const { theme } = React.useContext(ThemeContext);

  return (
    <HighLightPressable onPress={onPress} {...touchableHighlightProps}>
      <Icon
        {...iconProps}
        color={
          iconProps.color === undefined
            ? theme.colors!.primary
            : iconProps.color
        }
      />
    </HighLightPressable>
  );
};
