import { StatusBar } from "expo-status-bar";
import React from "react";
import Lottie from "react-lottie";
import { Text, View, ViewStyle } from "react-native";
import { Icon, ThemeProvider, useTheme } from "react-native-elements";
import { floatToPercent } from "./common/array";
import { Gallery } from "./components/Gallery";
import { Header } from "./components/Header";
import { HighLightPressable } from "./components/HighLightPressable";
import { NFTListContext, NFTStore } from "./contexts/NFTList";
import { AppTheme as theme } from "./contexts/Theme";

const RootView = ({ children }: { children: React.ReactChild }) => {
  const { theme } = useTheme();

  return (
    <View style={{ flex: 1, backgroundColor: theme.colors!.bg }}>
      {children}
    </View>
  );
};

const MasterListLoader = ({ children }: { children: React.ReactChild }) => {
  const { theme } = useTheme();
  const { nftList, error, reload } = React.useContext(NFTListContext);
  const center: Partial<ViewStyle> = {
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <>
      {children}
      {nftList.length <= 0 && (
        <View
          // @ts-ignore
          style={{
            position: "fixed",
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            backgroundColor: theme.colors?.iconBg,
            zIndex: 2,
            // paddingTop: insets.top,
          }}
        >
          {error === undefined ? (
            <View
              style={{
                flex: 1,
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  maxWidth: 500,
                  aspectRatio: 1,
                  justifyContent: "flex-end",
                  alignItems: "center",
                  width: "60%",
                }}
              >
                <Lottie
                  options={{
                    animationData: require("./assets/logo_anim.json"),
                  }}
                  width={"60%"}
                  autoPlay
                  loop
                />

                <Text
                  style={{
                    ...theme.common?.text("h1"),
                    top: "200%",
                    fontStyle: "italic",
                    marginTop: floatToPercent(theme.margins?.main!),
                  }}
                >
                  Loading...
                </Text>
              </View>
            </View>
          ) : (
            <HighLightPressable onPress={() => reload()}>
              <View
                style={{
                  flexDirection: "row",
                  ...center,
                }}
              >
                <Text
                  style={{
                    ...theme.common?.text("h1"),
                  }}
                >
                  Error Loading{"\n"}Tap to Retry{" "}
                </Text>
                <Icon
                  name="refresh"
                  size={theme.fonts?.h1! * 1.75}
                  color={theme.colors?.primary}
                />
              </View>
            </HighLightPressable>
          )}
        </View>
      )}
    </>
  );
};

export default function App() {
  const [headerHeight, setHeaderHeight] = React.useState(0);

  return (
    <ThemeProvider theme={theme}>
      <NFTStore>
        <RootView>
          <MasterListLoader>
            <>
              <Header setHeaderHeight={setHeaderHeight} />
              {headerHeight > 0 && (
                <View
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    flexDirection: "row",
                    top: headerHeight,
                    position: "absolute",
                  }}
                >
                  <Gallery />
                </View>
              )}
            </>
          </MasterListLoader>
        </RootView>
      </NFTStore>
      <StatusBar style="auto" />
    </ThemeProvider>
  );
}
